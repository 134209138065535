import { useQuery } from "@apollo/react-hooks";
import {
  CovidIntentByName,
  CovidIntentByNameVariables,
} from "../../../apollo-types/CovidIntentByName";
import {
  BlockchainIntentByName,
  BlockchainIntentByNameVariables,
} from "../../../apollo-types/BlockchainIntentByName";
import { COVID_INTENT_BY_NAME, BLOCKCHAIN_INTENT_BY_NAME } from "./query";

export const useQueryCovidIntentByName = (intentName: string) => {
  const { data, loading } = useQuery<
    CovidIntentByName,
    CovidIntentByNameVariables
  >(COVID_INTENT_BY_NAME, {
    variables: {
      intentName,
    },
    fetchPolicy: "cache-and-network",
  });

  const intent = data?.covidIntentByName;

  return { intent, loading };
};

export const useQueryBlockchainIntentByName = (intentName: string) => {
  const { data, loading } = useQuery<
    BlockchainIntentByName,
    BlockchainIntentByNameVariables
  >(BLOCKCHAIN_INTENT_BY_NAME, {
    variables: {
      intentName,
    },
    fetchPolicy: "cache-and-network",
  });

  const intent = data?.blockchainIntentByName;

  return { intent, loading };
};
