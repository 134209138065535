import { useStaticQuery, graphql } from "gatsby";
import { BlockchainIntentCategoriesQuery } from "../../../graphql-types";

export const useBlockchainIntentCategoriesQuery = () => {
  const data = useStaticQuery<BlockchainIntentCategoriesQuery>(
    graphql`
      query BlockchainIntentCategories {
        askAboutQuery {
          blockchainIntentCategories {
            ...IntentCategoryFrament
          }
        }
      }
    `
  );

  const categories = data.askAboutQuery.blockchainIntentCategories;

  return categories;
};
